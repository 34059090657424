<template>
  <div>
    <p class="text-center font-weight-bolder font-medium-5 mb-5">
      {{ $t('New Role') }}
    </p>
    <skeleton
      v-if="loader"
    />
    <error-alert :error="error" />
    <form-component
      :role="role"
      @submit="submit"
      @cancel="clear"
    />
  </div>
</template>

<script>

import { required, email } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import FormComponent from '../components/FormComponent.vue'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'RoleCreate',
  components: {
    ErrorAlert,
    FormComponent,
    Skeleton,
  },
  data() {
    return {
      required,
      email,
      error: {},
      loader: false,
    }
  },
  computed: {
    role() {
      return this.$store.state[this.MODULE_NAME].role
    },
    roleList() {
      return this.$store.state.listModule.roleList
    },
    warehouseList() {
      return this.$store.state.listModule.warehouseList
    },
  },
  created() {
    this.loader = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit('listModule/SET_PERMISSION_LIST', [])
    this.$store.dispatch('listModule/permissionList').then(() => {
      this.loader = false
    })
  },
  mounted() {
  },
  methods: {
    filterRole() {
      const role = { ...this.role }
      role.permissions = this.role.permissions.map(per => per.id)

      return role
    },
    submit(data, resetPermissions) {
      this.error = {}
      this.sendNotification(this, data, `${this.MODULE_NAME}/create`)
        .then(() => {
          this.clear()
          this.$router.push({ name: 'settings-users-role-list' })
        })
        .catch(err => {
          this.error = err.response?.data
        })
        .finally(() => {
          resetPermissions()
        })
    },

    clear() {
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.$router.push({ name: 'settings-users-role-list' })
    },
    getProps() {
      return {
        model: this.role,
        fields: this.fields,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'roles'

    return {
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
